<template>
<div>
    <div class="event-list-container">
        <!-- 이벤트 종료시 서브 클래스 추가 : event-list--closed -->
        <div v-for="board in boards" :key="board._id" class="event-list" :class="{ 'event-list--closed': board.category == '종료된 이벤트' }">
            <a class="box" :href="`${$route.path}/${board._id}?mode=view`">
                <div class="thumbnail">
                    <span class="image">
                        <span :style="{ backgroundImage: `url('${board.thumb}')` }"></span>
                    </span>
                    <span class="img"></span>
                </div>
                <div class="context">
                    <span class="title">{{ board.subject }}</span>
                    {{ board.summary }}
                </div>
            </a>
            <div class="info">
                <span class="state">{{ board.category }}</span>
                <span class="date">이벤트 기간 : {{ board.period }}</span>
            </div>
        </div>
    </div>
    <div v-if="boards.length < $props.summary.totalCount" class="bottom-button">
        <button class="button button--orange button--border" @click="$emit('loadmore')">더보기</button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        summary: { type: Object, default () { return { totalCount: 0 }} },
        boards: { type: Array, default: null }
    }
}
</script>

<style lang="scss" scoped>
// Style
.event-list-container { display: flex; flex-wrap: wrap; margin: -20px -18px; }
.event-list{
	padding: 20px 18px; width: 50%;
	.box { overflow: hidden; display: block; position: relative; border-radius: 15px; }
	.thumbnail { padding-top: calc(292 / 582 * 100%); }
	.thumbnail .image { -webkit-transition: all ease .8s; transition: all ease .8s; }
	.context{
		display: flex; flex-direction: column; justify-content: flex-end;
		z-index: 2; position: absolute; bottom: 0; left: 0; padding: 24px 30px; width: 100%; line-height: 1.4;
		font-size: 16px; color: #ffffff; font-weight: 300; background: rgba(0,0,0,.6);
		.title { display: block; margin-bottom: 6px; font-size: 24px; font-weight: 700; }
	}
	.info{
		display: flex; align-items: center; justify-content: space-between; margin: 10px 10px 0;
		font-size: 16px; color: #555555; font-weight: 300;
		.state { color: var(--v-primary-base); font-weight: 700; }
	}
	&--closed .thumbnail .image { filter: grayscale(100%); }
	&--closed .context { height: 100%; }
	&--closed .info .state { color: #d6d6d6; }
}
@media(min-width:1201px){
	.event-list .box:hover .thumbnail .image { -webkit-transform: scale(1.05); transform: scale(1.05); }
}
@media(max-width:1200px){
	.event-list-container { margin: -18px -7px; }
	.event-list{
		padding: 18px 7px;
		.context{
			font-size: 13px;
			.title { font-size: 16px; }
		}
		.info { font-size: 13px; }
	}
}
@media(max-width:768px){
	.event-list-container { margin: -16px 0; }
	.event-list{
		padding: 16px 0; width: 100%;
		.box { border-radius: 12px; }
		.thumbnail { padding-top: calc(320 / 582 * 100%); }
		.context { padding: 18px 20px; }
		.info { margin: 8px 6px 0; }
	}
}
</style>
